import React from 'react'

import { SiteContext } from 'context/site-context'

import {
    Redirect,
    Route,
    Switch,
    useLocation,
} from 'react-router-dom'

import { isValidState } from 'helpers'

import {
  injectElement,
  updateCanonical,
  updateMeta,
} from 'utilities'

const cleanData = (data ={}) => {
  for(const key in data){
    try {
      if(!data[key] || data[key] === undefined || data[key].toString().indexOf('undefined') > -1) {
        delete data[key]
      }
    } catch (e) {
      console.debug('error', key, data[key])
    }
  }
  return data
}

const AboutUs = React.lazy(() => import('routes/AboutUs'))
const Directory = React.lazy(() => import('routes/Directory'))
const Landing = React.lazy(() => import('routes/Landing'))
const Privacy = React.lazy(() => import('routes/Privacy'))
const PrivacyCalifornia = React.lazy(() => import('routes/PrivacyCalifornia'))
const Profile = React.lazy(() => import('routes/Profile'))
const Results = React.lazy(() => import('routes/Results'))
const Terms = React.lazy(() => import('routes/Terms'))
const World = React.lazy(() => import('routes/World'))

const App = () => {
  const [ site ] = React.useContext(SiteContext)

  const location = useLocation()

  React.useEffect( () => {
    try { // Don't let SEO crush the site...
      updateCanonical()

      let mapped_data = {}
      // Figure out which meta to update.
      switch (true) {
        case /\/results/.test(location.pathname):
          console.debug('/results')
          mapped_data = cleanData({ city: site.location, category: new URLSearchParams(window.location.search).get('term') })
          updateMeta(site.seo?.meta?.results || site.seo?.meta?.default, mapped_data)
          updateMeta(site.seo?.og?.results || site.seo?.og?.default, mapped_data, 'property')
          break
        case /\/profile/.test(location.pathname):
          console.debug('/profile')
          const data = site.profile_listing || {}
          const address = data.address || {}
          mapped_data = cleanData({
            name: data.name,
            city: (address.city || address.state) ? `${address.city}${address.city && ', '}${address.state}` : site.location,
            categories: data.categories?.map( category => category.name).join(', '),
            state: address.state,
            street: address.street,
            image: data.images && data.images.length > 0 && data.images[0] !== 'undefined' ? data.images[0].image_url : '',
            longitude: address.longitude,
            latitude: address.latitude,
            postal_code: address.postal_code,
            url: `${window.location.origin}${window.location.pathname}`,
            description: data.teaser,
          })
          updateMeta(site.seo?.meta?.profile || site.seo?.meta?.default, mapped_data)
          updateMeta(site.seo?.og?.profile || site.seo?.og?.default, mapped_data, 'property')
          break
        default:
          console.debug('/default')
          mapped_data = cleanData({ city: site.location })
          updateMeta(site.seo?.meta?.default, mapped_data)
          updateMeta(site.seo?.og?.default, mapped_data, 'property')
      }
    } catch (e) {
      console.error(e)
    }
  }, [location, site.seo, site.profile_listing])

  return (
    <React.Suspense fallback={<span></span>}>
      <Switch>
        <Route path="*/about" render={props => <AboutUs {...props} />}/>
        <Route path="*/privacy" render={props => <Privacy {...props} />}/>
        <Route path="*/privacy-california" render={props => <PrivacyCalifornia {...props} />}/>
        <Route path="*/results" render={props => <Results {...props} />}/>
        <Route path="*/terms" render={props => <Terms {...props} />}/>
        <Route path="*/world" render={props => <World {...props} />}/>

        <Route path="*/directory/:st/:page" render={props => <Directory {...props}
          page={props.match.params.page}
          st={props.match.params.st}/>} />

        <Route path="*/directory/:page" render={props => {
          return isValidState(props.match.params.page) ?
            <Directory {...props} st={props.match.params.page} page={1} /> :
            <Directory { ...props} page={props.match.params.page} />}
          }/>
        <Route path="*/directory" render={props => <Directory {...props} />}/>

        <Route path="*/suggested/:id" render={props => {
            props.match.params.slug = props.match.params.id.indexOf('.html') > -1 ? props.match.params.id : null
            props.match.params.id = props.match.params.id.indexOf('.html') > -1 ? null : props.match.params.id
            return <Profile {...props} slug={props.match.params.slug} />}
        }/>

        <Route path="*/profile/:id" render={props => {
            props.match.params.slug = props.match.params.id.indexOf('.html') > -1 ? props.match.params.id : null
            props.match.params.id = props.match.params.id.indexOf('.html') > -1 ? null : props.match.params.id
            return <Profile {...props} slug={props.match.params.slug} />}
        }/>

        <Route path="*/b/:id" render={props => {
            props.match.params.slug = props.match.params.id.indexOf('.html') > -1 ? props.match.params.id : null
            props.match.params.id = props.match.params.id.indexOf('.html') > -1 ? null : props.match.params.id
            return <Profile {...props} slug={props.match.params.slug} />}
        }/>

        <Route exact path="*/:st/:city" render={props => {
          return isValidState(props.match.params.st) ?
            <Results {...props} state={props.match.params.st} city={props.match.params.city} /> :
            <Landing { ...props} />}
          }/>
        <Route path="/" render={props => <Landing { ...props} />}/>

        <Redirect to={{ ...location, pathname: '/' }} />
      </Switch>
    </React.Suspense>
  )
}

export default App
