import React from 'react'

import {
  getGeoFromLocalStorage,
  getZip,
  updateLocalStorage,
} from 'helpers'

export const SiteContext = React.createContext()

export const SiteContextProvider = props => {
  const [ state, setState ] = React.useState({
    isLoadingSiteData: true,
    city: '',
    region: '',
    phone: '',
    location: '',
    upsellCategory: new URLSearchParams(window.location.search).get('term') || 'restaurants',
    geoImages: {},
    topBusinesses: {},
    popular_cities: [],
    categories: [
      'Automotive Repair',
      'Bathroom Remodeling Contractors',
      'Beauty Salons',
      'Chinese Restaurants',
      'Dentists',
      'Dermatologists',
      'General Contractors',
      'Internal Medicine Doctors',
      'Italian Restaurants',
      'Jewelry',
      'Kitchen Remodeling Contractors',
      'Landscapers',
      'Manicures',
      'Massage Therapists',
      'Mexican Restaurants',
      'Ob-Gyn',
      'Painters',
      'Pediatricians',
      'Pizza Restaurants',
      'Plumbers',
      'Preschools',
      'Restaurants',
      'Tires',
      'Veterinarians',
    ],
  })

  const metadataSrc = window.__path ? `/${window.__path}/metadata.json` : '/metadata.json'

  const geoInfo = getZip() ? {zip_code: getZip()} : getGeoFromLocalStorage()
  localStorage.setItem('geoInfo', JSON.stringify(geoInfo))

  updateLocalStorage()

  const getUpsellCategory = data => {
    // Select a random index of the possible options.  This is a plain object hat we need to temporarily treat like an array.
    const length = Object.entries(data).length
    const index = Math.floor(Math.random() * Object.entries(data).length)
    return Object.entries(data)[index][0]
  }

  const cleanTopBusinesses = (raw = []) => {
    return raw.filter( item => item.results.locations.length > 0 )
      .reduce( (obj, item) => {
        let term = new URLSearchParams(item.results.uri.split('?')[1]).get('what')
        // Need to 'hack' spa...
        if(term.trim() === 'spa'){
          term = 'spa & beauty'
        }
        obj[term] = item
        return obj
    }, {})
  }

  React.useEffect( () => {
    const updateContext = (metadata, geoInfo) => {
      // Finally, update the context object.
      setState( prevState => ({
        ...prevState,
        ...metadata,
        rialto: {
          ...metadata.rialto,
          publisher: metadata.rialto.publisher,
        },
        ...geoInfo,
      }))
      setTimeout( () => {
        setState( prevState => ({
          ...prevState,
          isLoadingSiteData: false,
        }))
      }, 500)
    }

    const fetchTopBusinesses = (metadata, geoInfo) => {
      updateContext(metadata, geoInfo)
      // // This has been abandoned.  (It was alwyas an ugly solution anyway...)
      // // We should wait until we have an API endpoint that will return TopBusinesses.
      // if(new URLSearchParams(window.location.search).get('useSampleData')) {
      //   metadata.topBusinesses = cleanTopBusinesses(topBusinesses)
      //   updateContext(metadata, geoInfo)
      // } else {
      //   if(state.location === geoInfo.location){
      //     const urls = Object.entries(metadata.discover).map( key => {
      //       return `{
      //       keywordAndGeoSearch(
      //         request: {
      //           keyword: "${key[0]}"
      //           geographyName: "${state.location}"
      //           listingSource:{
      //             listingOrganisation: "INSIDERPAGES"
      //           }
      //         },
      //         page: 0,
      //         pageSize: 6){
      //           count
      //           pageSize
      //           currentPage
      //           data{
      //             importedListingId
      //             listingName
      //             address {
      //               street1
      //               street2
      //               city
      //               stateOrProvince
      //               postalCode
      //             }
      //             organicPhone
      //           }
      //         }
      //       }`})
      //     // https://indexer.viking-dev.elocal.com/viking-indexer/graphql
      //     Promise.all(urls.map( url => fetch('http://internal-viking-dev-indexer-alb-1182139241.us-east-1.elb.amazonaws.com/viking-indexer/graphql ', {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json'
      //       },
      //       body: JSON.stringify({
      //         query: url
      //       })
      //     }) ))
      //     .then( responses => Promise.all(responses.map( response => response.json() )))
      //     .then( data => {
      //       // At this point there's no direct association between the business category and the array sets...  hmmm...
      //       console.log(data.map( response => console.log(response.data.keywordAndGeoSearch.data)))
      //       // metadata.topBusinesses = cleanTopBusinesses(topBusinesses)
      //       updateContext(metadata, geoInfo)
      //     }).catch( err => {
      //       console.log('error retrieving listings - promises', err)
      //       updateContext(metadata, geoInfo)
      //     })
      //   } else {
      //     updateContext(metadata, geoInfo)
      //   }
      // }
    }

    const fetchGeoinfo = metadata => {
      fetch('//ipwhois.pro/?key=J7OPppp50QNcRxNv')
        .then(response => response.json())
        .then(geoAPIData => {
          // console.log(geoAPIData)
          if(geoAPIData) {
            const geo = {
              zip_code: geoAPIData.postal || ''
            }

            localStorage.setItem('geoInfo', JSON.stringify(geo))

            fetchTopBusinesses(metadata, geo)
          } else {
            fetchTopBusinesses(metadata, {})
          }
        })
        .catch( err => {
          fetchTopBusinesses(metadata, {})
        })
    }

    fetch(metadataSrc)
      .then(response => response.json())
      .then(metadata => {
        // Update metadata from qs.
        const partner_campign_id_override = new URLSearchParams(window.location.search).get('partner_campaign_id')
        metadata.partner_campaign_ids = partner_campign_id_override ? [ partner_campign_id_override.toString() ] : metadata.partner_campaign_ids

        metadata.rialto = metadata.rialto || {}
        metadata.rialto.publisher = new URLSearchParams(window.location.search).get('publisher') || metadata.rialto.publisher

        // Set upsellCategory.
        metadata.upsellCategory = getUpsellCategory(metadata.discover)

        if(geoInfo) {
          fetchTopBusinesses(metadata, geoInfo)
        } else {
          fetchGeoinfo(metadata)
        }
      })
      .catch( err => {
        console.log('metadata fetch failed')
        // _Could_ redirect to a 500 page...
      })
  }, [state.location])

  return (
    <SiteContext.Provider value={[ state, setState ]}>
      {props.children}
    </SiteContext.Provider>
  )
}
